import React from "react"
import Layout from "./src/components/Layout";

import "./src/styles/style";
import "./src/styles/global.css";

const isBrowser = typeof window !== "undefined"

export const onServiceWorkerUpdateReady = () => {
    const shouldReload = window.confirm(
      "This website has been updated. Reload to display the latest version?"
    )
  
    if (shouldReload) {
      window.location.reload()
      //window.location.href = window.location.href.replace(/#.*$/, "")
    }
  }
  
  export const onRouteUpdate = async () => {
    if (!navigator) {
      console.log(
        "Navigator is not defined, skipping service worker registration..."
      )
      return
    }
    if (!navigator.serviceWorker) {
      console.log("Service worker is not supported, skipping registration...")
      return
    }
    try {
      const registration = await navigator.serviceWorker.register("/sw.js")
      await registration.update()
    } catch (error) {
      console.error("Service worker registration failed", error)
    }
  }
  
  export const wrapPageElement = ({ element }) => {
    return <>{isBrowser && <Layout>{element}</Layout>}</>
  }