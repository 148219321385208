import React, { useEffect } from "react";
import Navbar from "./Navbar";
import styles from "../styles/style";
import Footer from "./Footer";
//import Hero from "./Hero";
//import HeroV2 from "./HeroV2";

const Layout = ({ children }) => {
  const [hasMounted, setHasMounted] = React.useState(false);

  useEffect(() => {
    // trigger another render, reconciliation after the first mount, updating the dom with regards to rehydration
    //setHasMounted(true);
    if (typeof window !== "undefined") {
      try {
        setHasMounted(true);
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <div className="w-full overflow-clip ">
      <div
        className={`bg-[#1d1d1d] sticky top-0 z-30 ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={` ${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>

      <main>{children}</main>

      {/* <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <main>{children}</main>
        </div>
      </div> */}

      <div
        className={`bg-[#1d1d1d] mt-6 ${styles.paddingX} ${styles.flexCenter}`}
      >
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
