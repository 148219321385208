import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";

import { StaticImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

import { AnchorLink } from "gatsby-plugin-anchor-links";

//import { socialMedia } from "../constants";

import { close, menu } from "../images";

// import component
import Drawer from "react-modern-drawer";

//import styles
import "react-modern-drawer/dist/index.css";
import styles from "../styles/style";

import { AiOutlineHome } from "react-icons/ai";
import {
  MdOutlinePeopleAlt,
  MdOutlineElectricalServices,
 // MdOutlineGroupAdd,
} from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import { GiNewspaper } from "react-icons/gi";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BiSolidContact } from "react-icons/bi";
import { FaPeopleLine } from "react-icons/fa6";

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      jobListImages: allFile(
        filter: {
          name: {
            in: [
              "05-alfie-barnard"
              "24-richard-working"
            ]
          }
        }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              formats: AUTO
              layout: CONSTRAINED
              placeholder: BLURRED
              aspectRatio: 1.1
            )
          }
        }
      }
    }
  `);

  //const [active, setActive] = useState("Home");
  const [hasMounted, setHasMounted] = React.useState(false);
  //const [toggle, setToggle] = useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  //useEffect(() => initTE({ Offcanvas, Collapse, Ripple }), []);

  useEffect(() => {
    // trigger another render, reconciliation after the first mount, updating the dom with regards to rehydration
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    setHasMounted(true);
  }

  const traineeElectrician = data.jobListImages.nodes.find((image) => {
    return image.name === "05-alfie-barnard";
  });

  const electricalEngineer = data.jobListImages.nodes.find((image) => {
    return image.name === "24-richard-working";
  });

  return (
    <nav className="w-full flex py-4 justify-between items-center">
      <Link to="/" alt="home page">
        <div
          className="text-[#FFFFFF] text-2xl font-bold"
          style={{ maxWidth: "30px", display: "block" }}
        >
          <StaticImage
            src="../images/logo-icon.jpg"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="Robot"
          />
        </div>
      </Link>

      <ul className="md:flex hidden items-center justify-center font-semibold">
        <li className="relative group px-3 py-2 text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default "
            aria-haspopup="true"
          >
           <Link to="/" alt="home page" activeClassName="active">
              Home
            </Link>
          </button>
          <div className="absolute ml-20 md:-left-48 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[680px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-0 transition-transform md:-group-hover:translate-x-[2rem] group-hover:translate-x-[6.5rem] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Intact electrical
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/#trusted-services"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Electrical services
                          <p className="text-quinary  font-normal">
                            you can trust
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/#featured-home-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Let us handle
                          <p className="text-quinary  font-normal">
                            your next project
                          </p>
                        </AnchorLink>
                      </li>

                      <li className="group">
                        <AnchorLink
                          to="/#domestic-electrical-services"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Domestic
                          <p className="text-quinary font-normal">
                            electrical services
                          </p>
                        </AnchorLink>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Where expectation
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/#commercial-electrical-services"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Commercial
                          <p className="text-quinary font-normal">
                            electrical services
                          </p>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink
                          to="/#training-and-certifications"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Training & electrical
                          <p className="text-quinary font-normal">
                            certifications
                          </p>
                        </AnchorLink>
                      </li>
                      <li>
                        <AnchorLink
                          to="/#medivet-project-header"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Medivet Watford
                          <p className="text-quinary font-normal">
                            the ultimate care
                          </p>
                        </AnchorLink>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      becomes a reality
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/#home-photo-gallery"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Client gallery
                          <p className="text-quinary  font-normal">
                            see our work
                          </p>
                        </AnchorLink>
                      </li>

                      <li className="group">
                        <AnchorLink
                          to="/#articles"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Featured articles
                          <p className="text-quinary font-normal">
                            latest news
                          </p>
                        </AnchorLink>
                      </li>

                      <li className="group">
                        <AnchorLink
                          to="/#testimonial-slider-dark"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          What people say
                          <p className="text-quinary  font-normal">about us</p>
                        </AnchorLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="relative group text-white hover:text-quinary">
          <button className="hover:opacity-50 cursor-default">
            <Link to="/about-us" alt="about us page" activeClassName="active">About</Link>
          </button>
          <div className="absolute  md:-left-28 top-1 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[880px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              {/* <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-4 transition-transform group-hover:translate-x-[106px] duration-500 ease-in-out rounded-sm"></div> */}
              {/* <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0  -translate-x-0 transition-transform md:-group-hover:translate-x-[2rem] group-hover:translate-x-[6.2rem] duration-500 ease-in-out rounded-sm"></div> */}
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-0 transition-transform md:-group-hover:translate-x-[2rem] group-hover:translate-x-[6.5rem] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-4 gap-6 ">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      See Who we are
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/about-us#featured-about-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Our expectation
                          <p className="text-quinary  font-normal">
                            became reality
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/about-us#leadershipTeam"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Leadership
                          <p className="text-quinary font-normal">
                            team profiles
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/about-us#talented-people"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Our talent
                          <p className="text-quinary font-normal">on site</p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/about-us#whyChooseUs"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Why Choose us?
                          <p className="text-quinary font-normal">
                            your first call
                          </p>
                        </AnchorLink>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      See what we do
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/about-us#dhl-project-header"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          DHL Reading
                          <p className="text-quinary font-normal">
                            simply delivered
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/about-us#about-photo-gallery"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Team gallery
                          <p className="text-quinary  font-normal">
                            us at work
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <Link
                          to="/about-us#testimonial-slider-dark"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          What people say
                          <p className="text-quinary font-normal">about us</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Operations
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/help-centre"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Help centre
                          <p className="text-quinary  font-normal">
                            your enquires
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/terms-and-conditions"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Terms & conditions
                          <p className="text-quinary font-normal">
                            rules & regulations
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/privacy-policy"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Privacy policy
                          <p className="text-quinary font-normal">
                            data protection
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/accreditations"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Accreditations
                          <p className="text-quinary font-normal">
                            Industry approved
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Community
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/partners"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Partnerships
                          <p className="text-quinary  font-normal">
                            community first
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/join-us"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Join us
                          <p className="text-quinary font-normal">
                            let's collaborate
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/careers"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Careers
                          <p className="text-quinary font-normal">
                            employee satisfaction
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/articles/slough-in-bloom"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Latest event
                          <p className="text-quinary font-normal">
                            Slough in bloom 2023
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="relative group px-3 py-2 text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default "
            aria-haspopup="true"
          >
            <Link to="/services" alt="Services page" activeClassName="active">Services</Link>
          </button>
          <div className="absolute md:-left-48 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[880px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0  -translate-x-0 transition-transform md:-group-hover:translate-x-[2.5rem] group-hover:translate-x-[12rem] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-4 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Featured Sections
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/services/#featured-services-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Look no further
                          <p className="text-quinary  font-normal">
                            call us today
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/services/#our-services-gallery"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Our range
                          <p className="text-quinary font-normal">
                            of services
                          </p>
                        </AnchorLink>
                      </li>
                      <li className="group">
                        <AnchorLink
                          to="/services/#ladbrokes-project-header"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Ladbrokes Woking
                          <p className="text-quinary font-normal">
                            a safe bet
                          </p>
                        </AnchorLink>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="invisible uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Featured Sections
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <AnchorLink
                          to="/services/#photo-gallery"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Services gallery
                          <p className="text-quinary font-normal">
                            on display
                          </p>
                        </AnchorLink>
                      </li>
                      {/* <li className="group">
                        <AnchorLink
                          to="/services/#featured-services-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Look no further
                          <p className="text-quinary  font-normal">
                            Call us today
                          </p>
                        </AnchorLink>
                      </li> */}

                      <li className="group">
                        <AnchorLink
                          to="/services/#testimonial-slider-dark"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          What people say
                          <p className="text-quinary font-normal">about us</p>
                        </AnchorLink>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Our Services
                    </p>
                    <ul className="mt-4 text-[15px]">
                      <li>
                        <Link
                          to="/services/electrical-installations"
                          alt="Electrical installations"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Electrical installations
                          <p className="text-quinary font-normal">
                            sockets & fittings
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/eicers-and-pat-testing"
                          alt="eicers and pat testing"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          EICR & PAT
                          <p className="text-quinary font-normal">
                            health & safety
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/wiring-services"
                          alt="wiring services"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Wiring services
                          <p className="text-quinary font-normal">
                            full or partial
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <p className="invisible  uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Bespoke Services
                    </p>
                    <ul className="mt-4 text-[15px]">
                      <li>
                        <Link
                          to="/services/lighting-services"
                          alt="lighting services"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Lighting solutions
                          <p className="text-quinary font-normal">
                            internal & external
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/fuse-boards"
                          alt="Fuse boards"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Fuse boards
                          <p className="text-quinary font-normal">
                            upgrades and testing
                          </p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/services/security-solutions"
                          alt="security solutions"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Security solutions
                          <p className="text-quinary font-normal">
                            peace of mind
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="px-3 py-2 relative group text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default"
            //onClick={() => reloadThePage()}
          >
            <Link to="/projects" alt="Projects page" activeClassName="active">Projects</Link>
          </button>
          <div className="absolute md:-left-28 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[680px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-4 transition-transform group-hover:translate-x-[114px] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Featured Sections
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/projects#featured-project-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Why our clients
                          <p className="text-quinary  font-normal">trust us</p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects#our-projects-gallery"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Our client
                          <p className="text-quinary font-normal">projects</p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects#photo-gallery"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Project gallery
                          <p className="text-quinary  font-normal">showcase</p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects#testimonial-slider-dark"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          What people say
                          <p className="text-quinary font-normal">about us</p>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Featured Projects
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/projects/medivet-watford"
                          alt="medivet watford project"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Medivet - Watford
                          <p className="text-quinary font-normal">
                            the ultimate care
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects/biffa-workshop"
                          alt="biffa workshop project"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Biffa workshop
                          <p className="text-quinary  font-normal">
                            no time to waste
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects/dhl-reading"
                          alt="dhl reading project"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          DHL - Reading
                          <p className="text-quinary  font-normal">
                            simply delivered
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="invisible uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Featured Projects
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/projects/ladbrokes-woking"
                          alt="ladbrokes woking project"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Ladbrokes Woking
                          <p className="text-quinary font-normal">
                            a safe bet
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects/harvey-nichols"
                          alt="harvey nichols project"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Harvey Nichols
                          <p className="text-quinary font-normal">
                            gourmet service
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/projects/domestic-installations"
                          alt="domestic installations project"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Domestic project
                          <p className="text-quinary  font-normal">
                            no visible wires
                          </p>
                        </Link>
                      </li>
                      {/* <li className="group">
                        <Link
                          to="/projects/the-hub-farnborough"
                          alt="the hub farnborough project"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          The Hub Farnborough
                          <p className="text-quinary  font-normal">
                            flying high
                          </p>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="px-3 py-2 relative group text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default"
          >
            <Link to="/news" alt="News page" activeClassName="active">News</Link>
          </button>
          <div className="absolute md:-left-28 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[400px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-4 transition-transform group-hover:translate-x-[105px] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                {/* <Link
                  to="#"
                  className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                >
                  Intact Elecrtical
                  <p className="text-quinary font-normal">
                    Where expectation becomes a reality
                  </p>
                </Link> */}
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Latest news
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/news/#featured-article-news-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Featured project
                          <p className="text-quinary  font-normal">
                            commercial contract
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/news/#latest-news"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Latest update
                          <p className="text-quinary font-normal">
                            from the community
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/news/#articles-popular-topics"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Popular topics
                          <p className="text-quinary font-normal">
                            articles & events
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="invisible uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Commercial Projects
                    </p>
                    <ul className="mt-3 text-[15px]">
                      {/* <li className="group">
                        <Link
                          to="/news/#longcross-studios-project-header"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Featured Project
                          <p className="text-quinary font-normal">
                            Longcross Studios
                          </p>
                        </Link>
                      </li> */}
                      <li className="group">
                        <Link
                          to="/news/#photo-gallery"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Events gallery
                          <p className="text-quinary  font-normal">
                            what's new
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/news/#testimonial-slider-dark"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          What people say
                          <p className="text-quinary font-normal">about us</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="px-3 py-2 relative group text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default"
          >
            <Link to="/partners" alt="Partners page" activeClassName="active">Partners</Link>
          </button>
          <div className="absolute md:-left-28 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[540px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-4 transition-transform group-hover:translate-x-[110px] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Our Partners
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/partners/#partnership-featured-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Putting our
                          <p className="text-quinary  font-normal">
                            community first
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/partners/#burnham-football-cub"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Burnham Junior FC
                          <p className="text-quinary font-normal">
                            our pride & joy
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/partners/#slough-in-bloom"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Slough In Bloom
                          <p className="text-quinary font-normal">
                            brighten up the town
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Connecting the community
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/partners/#hs-pepper-co"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          H&S Pepper Co
                          <p className="text-quinary font-normal">
                            homegrown goodness
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/partners/#testimonial-slider-dark"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          What people say
                          <p className="text-quinary font-normal">about us</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="px-3 py-2 relative group text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default"
          >
            <Link to="/join-us" alt="Join us page" activeClassName="active">Join</Link>
          </button>

          <div className="absolute md:-left-2 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[200px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-4 transition-transform group-hover:translate-x-[6px] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Joining Forces
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/join-us/#join-us-featured-content"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Collaborate
                          <p className="text-quinary  font-normal">
                            partner with us
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/join-us/#how-it-works"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Partnership
                          <p className="text-quinary  font-normal">
                            how it works
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="#get-started"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Get started
                          <p className="text-quinary  font-normal">
                            let's connect
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className="px-3 py-2 relative group text-white hover:text-quinary">
          <button
            className="hover:opacity-50 cursor-default"
          >
            <Link to="/contact-us" alt="Contact us page" activeClassName="active">Contact</Link>
          </button>

          <div className="absolute md:-left-2 top-3 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[200px] transform">
            <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
              <div className="w-10 h-10 bg-quinary z-[-2] transform rotate-45 absolute top-0 -translate-x-4 transition-transform group-hover:translate-x-[10px] duration-500 ease-in-out rounded-sm"></div>
              <div className="relative z-10">
                <div className="grid grid-cols-1 gap-6">
                  <div>
                    <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                      Givs us a call
                    </p>
                    <ul className="mt-3 text-[15px]">
                      <li className="group">
                        <Link
                          to="/contact-us/#contact-us-featured-content"
                          className="block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"                          
                        >
                          Lets connect
                          <p className="text-quinary  font-normal">
                            & grow together
                          </p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/contact-us/#contact-form"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          Get in touch
                          <p className="text-quinary  font-normal">with us</p>
                        </Link>
                      </li>
                      <li className="group">
                        <Link
                          to="/contact-us/#where-we-are"
                          className=" block p-2 -mx-2 rounded-lg hover:bg-[#444343] transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]"
                        >
                          We are here
                          <p className="text-quinary  font-normal">
                            our location
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>

      {/* <div className="md:flex hidden justify-end items-center ml-12 ">
        {socialMedia.map((social, index) => (
          <a
            key={social.id}
            href={social.link}
            className="mx-2 text-gray-600 "
            aria-label="Facebook"
          >
            <img
              //key={social.id}
              src={social.icon}
              alt={social.id}
              fill-current="true"
              className={`w-[24px] h-[24px] object-contain cursor-pointer grow ${
                index !== socialMedia.length - 1 ? "mr-4" : "mr-0"
              }`}
              role="presentation"
              style={{}}
            />
          </a>
        ))}
      </div> */}

      <div className="md:flex hidden justify-end items-center">
        <a
          href="https://www.instagram.com/intact_electrical/"
          alt="link to Instagram"
          className="mx-2 text-white hover:text-quinary"
          aria-label="Instagram icon"
          title="Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="w-7 h-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12,6.865A5.135,5.135,0,1,0,17.135,12,5.135,5.135,0,0,0,12,6.865Zm0,8.469A3.334,3.334,0,1,1,15.334,12,3.334,3.334,0,0,1,12,15.334Zm6.539-8.672a1.2,1.2,0,1,1-1.2-1.2h0A1.2,1.2,0,0,1,18.539,6.662ZM21.476,5.45a4.917,4.917,0,0,0-1.154-1.772,4.894,4.894,0,0,0-1.77-1.153,7.318,7.318,0,0,0-2.428-.464C15.058,2.012,14.717,2,12,2s-3.057.011-4.124.061a7.326,7.326,0,0,0-2.427.464A4.9,4.9,0,0,0,3.679,3.678,4.882,4.882,0,0,0,2.525,5.45a7.332,7.332,0,0,0-.464,2.427C2.011,8.943,2,9.284,2,12s.011,3.057.061,4.123a7.332,7.332,0,0,0,.464,2.427,4.882,4.882,0,0,0,1.154,1.772A4.915,4.915,0,0,0,5.45,21.475a7.337,7.337,0,0,0,2.427.464C8.944,21.988,9.285,22,12,22s3.057-.011,4.123-.061a7.333,7.333,0,0,0,2.428-.464,5.113,5.113,0,0,0,2.925-2.925,7.306,7.306,0,0,0,.464-2.427c.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123A7.326,7.326,0,0,0,21.476,5.45ZM20.141,16.041A5.52,5.52,0,0,1,19.8,17.9a3.31,3.31,0,0,1-1.9,1.9,5.546,5.546,0,0,1-1.857.344c-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058A5.546,5.546,0,0,1,6.1,19.8a3.1,3.1,0,0,1-1.15-.748,3.092,3.092,0,0,1-.748-1.15,5.494,5.494,0,0,1-.344-1.857C3.812,14.987,3.8,14.671,3.8,12s.01-2.986.058-4.041A5.552,5.552,0,0,1,4.205,6.1a3.1,3.1,0,0,1,.748-1.15A3.072,3.072,0,0,1,6.1,4.2,5.494,5.494,0,0,1,7.96,3.86C9.014,3.811,9.331,3.8,12,3.8s2.987.011,4.042.059A5.552,5.552,0,0,1,17.9,4.2a3.31,3.31,0,0,1,1.9,1.9,5.494,5.494,0,0,1,.344,1.857C20.19,9.014,20.2,9.33,20.2,12S20.19,14.986,20.141,16.041Z"/></svg>
        </a> 

        <a
          href="https://www.facebook.com/intactelectrics"
          alt="link to facebook"
          className="mx-2 text-white hover:text-quinary"
          aria-label="Facebook icon"
          title="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            className="w-7 h-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
          </svg>
        </a>

        <a
          href="https://uk.linkedin.com/company/intact-electrical-limited"
          alt="link to LinkedIn"
          className="ml-2 text-white hover:text-quinary"
          aria-label="LinkedIn icon"
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="w-6 h-6 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
          <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
          <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" />
          </svg>
        </a> 
      </div>

      {/* <div className="md:hidden flex flex-row hover:text-quinary ">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[22px] h-[22px] object-contain cursor-pointer hover:text-quinary ${
              index !== socialMedia.length - 1 ? "mr-4" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
            role="presentation"
          />
        ))}
      </div> */}

      <div className="md:hidden flex flex-row hover:text-quinary ">
        <a
          href="https://www.instagram.com/intact_electrical/"
          alt="link to Instagram"
          className="mx-2 text-white hover:text-quinary"
          aria-label="Instagram icon"
          title="Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="w-7 h-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12,6.865A5.135,5.135,0,1,0,17.135,12,5.135,5.135,0,0,0,12,6.865Zm0,8.469A3.334,3.334,0,1,1,15.334,12,3.334,3.334,0,0,1,12,15.334Zm6.539-8.672a1.2,1.2,0,1,1-1.2-1.2h0A1.2,1.2,0,0,1,18.539,6.662ZM21.476,5.45a4.917,4.917,0,0,0-1.154-1.772,4.894,4.894,0,0,0-1.77-1.153,7.318,7.318,0,0,0-2.428-.464C15.058,2.012,14.717,2,12,2s-3.057.011-4.124.061a7.326,7.326,0,0,0-2.427.464A4.9,4.9,0,0,0,3.679,3.678,4.882,4.882,0,0,0,2.525,5.45a7.332,7.332,0,0,0-.464,2.427C2.011,8.943,2,9.284,2,12s.011,3.057.061,4.123a7.332,7.332,0,0,0,.464,2.427,4.882,4.882,0,0,0,1.154,1.772A4.915,4.915,0,0,0,5.45,21.475a7.337,7.337,0,0,0,2.427.464C8.944,21.988,9.285,22,12,22s3.057-.011,4.123-.061a7.333,7.333,0,0,0,2.428-.464,5.113,5.113,0,0,0,2.925-2.925,7.306,7.306,0,0,0,.464-2.427c.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123A7.326,7.326,0,0,0,21.476,5.45ZM20.141,16.041A5.52,5.52,0,0,1,19.8,17.9a3.31,3.31,0,0,1-1.9,1.9,5.546,5.546,0,0,1-1.857.344c-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058A5.546,5.546,0,0,1,6.1,19.8a3.1,3.1,0,0,1-1.15-.748,3.092,3.092,0,0,1-.748-1.15,5.494,5.494,0,0,1-.344-1.857C3.812,14.987,3.8,14.671,3.8,12s.01-2.986.058-4.041A5.552,5.552,0,0,1,4.205,6.1a3.1,3.1,0,0,1,.748-1.15A3.072,3.072,0,0,1,6.1,4.2,5.494,5.494,0,0,1,7.96,3.86C9.014,3.811,9.331,3.8,12,3.8s2.987.011,4.042.059A5.552,5.552,0,0,1,17.9,4.2a3.31,3.31,0,0,1,1.9,1.9,5.494,5.494,0,0,1,.344,1.857C20.19,9.014,20.2,9.33,20.2,12S20.19,14.986,20.141,16.041Z"/></svg>
        </a> 

        <a
          href="https://www.facebook.com/intactelectrics"
          alt="link to facebook"
          className="mx-2 text-white hover:text-quinary"
          aria-label="Facebook icon"
          title="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            className="w-7 h-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
          </svg>
        </a>

        <a
          href="https://uk.linkedin.com/company/intact-electrical-limited"
          alt="link to LinkedIn"
          className="mx-2 text-white hover:text-quinary"
          aria-label="LinkedIn icon"
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="w-6 h-6 fill-current mt-[2.5px]"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
          <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
          <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" />
          </svg>
        </a> 
      </div>


      <div className="md:hidden flex justify-end items-center overflow-visible h-full">
        {/* <button onClick={toggleDrawer}> */}
        
        <img
          src={menu}
          alt="menu"
          className="w-[28px] h-[28px] object-contain"
          role="presentation"
          onClick={toggleDrawer}
        />
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className="flex-grow-0 overflow-y-auto h-full"
          style={{ width: "100%" }}
        >
          <div className="flex items-center justify-between p-4 bg-[#1d1d1d] sticky top-0 z-30">
            <div>
            <Link to="/" alt="intact electrical logo link to home">
                <div
                  className="text-[#FFFFFF] text-2xl font-bold"
                  style={{ maxWidth: "30px", display: "block" }}
                >
                  <StaticImage
                    src="../images/logo-icon.jpg"
                    placeholder="blurred"
                    formats={["auto", "webp", "avif"]}
                    alt="Robot"
                  />
                </div>
              </Link>
            </div>

            {/* <div className="md:hidden flex flex-row hover:text-quinary ">
              {socialMedia.map((social, index) => (
                <img
                  key={social.id}
                  src={social.icon}
                  alt={social.id}
                  className={`w-[22px] h-[22px] object-contain cursor-pointer hover:text-quinary ${
                    index !== socialMedia.length - 1 ? "mr-4" : "mr-0"
                  }`}
                  onClick={() => window.open(social.link)}
                  role="presentation"
                />
              ))} 
            </div>*/}
            
            <div className="md:hidden flex flex-row hover:text-quinary ">
        <a
          href="https://www.instagram.com/intact_electrical/"
          alt="link to Instagram"
          className="mx-2 text-white hover:text-quinary"
          aria-label="Instagram icon"
          title="Instagram"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="w-7 h-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12,6.865A5.135,5.135,0,1,0,17.135,12,5.135,5.135,0,0,0,12,6.865Zm0,8.469A3.334,3.334,0,1,1,15.334,12,3.334,3.334,0,0,1,12,15.334Zm6.539-8.672a1.2,1.2,0,1,1-1.2-1.2h0A1.2,1.2,0,0,1,18.539,6.662ZM21.476,5.45a4.917,4.917,0,0,0-1.154-1.772,4.894,4.894,0,0,0-1.77-1.153,7.318,7.318,0,0,0-2.428-.464C15.058,2.012,14.717,2,12,2s-3.057.011-4.124.061a7.326,7.326,0,0,0-2.427.464A4.9,4.9,0,0,0,3.679,3.678,4.882,4.882,0,0,0,2.525,5.45a7.332,7.332,0,0,0-.464,2.427C2.011,8.943,2,9.284,2,12s.011,3.057.061,4.123a7.332,7.332,0,0,0,.464,2.427,4.882,4.882,0,0,0,1.154,1.772A4.915,4.915,0,0,0,5.45,21.475a7.337,7.337,0,0,0,2.427.464C8.944,21.988,9.285,22,12,22s3.057-.011,4.123-.061a7.333,7.333,0,0,0,2.428-.464,5.113,5.113,0,0,0,2.925-2.925,7.306,7.306,0,0,0,.464-2.427c.049-1.067.06-1.407.06-4.123s-.011-3.057-.06-4.123A7.326,7.326,0,0,0,21.476,5.45ZM20.141,16.041A5.52,5.52,0,0,1,19.8,17.9a3.31,3.31,0,0,1-1.9,1.9,5.546,5.546,0,0,1-1.857.344c-1.054.048-1.371.058-4.042.058s-2.986-.01-4.04-.058A5.546,5.546,0,0,1,6.1,19.8a3.1,3.1,0,0,1-1.15-.748,3.092,3.092,0,0,1-.748-1.15,5.494,5.494,0,0,1-.344-1.857C3.812,14.987,3.8,14.671,3.8,12s.01-2.986.058-4.041A5.552,5.552,0,0,1,4.205,6.1a3.1,3.1,0,0,1,.748-1.15A3.072,3.072,0,0,1,6.1,4.2,5.494,5.494,0,0,1,7.96,3.86C9.014,3.811,9.331,3.8,12,3.8s2.987.011,4.042.059A5.552,5.552,0,0,1,17.9,4.2a3.31,3.31,0,0,1,1.9,1.9,5.494,5.494,0,0,1,.344,1.857C20.19,9.014,20.2,9.33,20.2,12S20.19,14.986,20.141,16.041Z"/></svg>
        </a> 

        <a
          href="https://www.facebook.com/intactelectrics"
          alt="link to facebook"
          className="mx-2 text-white hover:text-quinary"
          aria-label="Facebook icon"
          title="Facebook"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            className="w-7 h-7 fill-current"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.00195 12.002C2.00312 16.9214 5.58036 21.1101 10.439 21.881V14.892H7.90195V12.002H10.442V9.80204C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90204C14.9655 5.91417 15.7141 5.98101 16.455 6.10205V8.56104H15.191C14.7558 8.50405 14.3183 8.64777 14.0017 8.95171C13.6851 9.25566 13.5237 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8174 21.0506 22.502 16.2518 21.9475 10.9611C21.3929 5.67041 16.7932 1.73997 11.4808 2.01722C6.16831 2.29447 2.0028 6.68235 2.00195 12.002Z"></path>
          </svg>
        </a>

        <a
          href="https://uk.linkedin.com/company/intact-electrical-limited"
          alt="link to LinkedIn"
          className="mx-2 text-white hover:text-quinary"
          aria-label="LinkedIn icon"
          title="LinkedIn"
          target="_blank"
          rel="noreferrer"
        >
          <svg className="w-6 h-6 fill-current mt-[2.4px]"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z" />
          <path d="M5 10C5 9.44772 5.44772 9 6 9H7C7.55228 9 8 9.44771 8 10V18C8 18.5523 7.55228 19 7 19H6C5.44772 19 5 18.5523 5 18V10Z" />
          <path d="M11 19H12C12.5523 19 13 18.5523 13 18V13.5C13 12 16 11 16 13V18.0004C16 18.5527 16.4477 19 17 19H18C18.5523 19 19 18.5523 19 18V12C19 10 17.5 9 15.5 9C13.5 9 13 10.5 13 10.5V10C13 9.44771 12.5523 9 12 9H11C10.4477 9 10 9.44772 10 10V18C10 18.5523 10.4477 19 11 19Z" />
          <path fillRule="evenodd" clipRule="evenodd" d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z" />
          </svg>
        </a> 
      </div>      
      <button
        type="button"
        className="box-content rounded-none border-none hover:no-underline  focus:shadow-none focus:outline-none "
        onClick={toggleDrawer}
      >
        <img
          src={close}
          alt="menu"
          className="w-[24px] h-[24px] object-contain text-black"
          role="presentation"
          style={{ color: "red" }}
        />
      </button>
    </div>
      <div className="offcanvas-body flex-grow overflow-y-auto p-4 overflow-visible">
        <ul className=" items-center justify-center font-semibold grid ss:grid-cols-2 grid-cols-1 gap-x-4 gap-y-0 mt-6">
          <div><li className="relative group">
            <button
            type="button"
            onClick={toggleDrawer}
            >
              <Link to="/" alt="home page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <AiOutlineHome className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Home</p>
                    <p className="text-sm text-quinary">
                      where it all starts
                    </p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          <li className="relative group">
          <button
            type="button"
            onClick={toggleDrawer}
            >
              <Link to="/about-us" alt="about us page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <MdOutlinePeopleAlt className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">About us</p>
                    <p className="text-sm text-quinary">
                      all you need to know
                    </p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          <li className="relative group">
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/services" alt="Services page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <MdOutlineElectricalServices className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Services</p>
                    <p className="text-sm text-quinary">
                      end to end electricals
                    </p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          <li className="relative group">
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/projects" alt="Projects page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <GoProjectSymlink className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Projects</p>
                    <p className="text-sm text-quinary">our work on show</p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          </div>
          <div>
          <li className="relative group">            
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/news" alt="Nerws page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <GiNewspaper className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">News</p>
                    <p className="text-sm text-quinary">articles & events</p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          <li className="relative group">           
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/partners" alt="partners page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <HiOutlineUserGroup className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Partners</p>
                    <p className="text-sm text-quinary">driving collaboration</p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          <li className="relative group">
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/join-us" alt="Join us page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <FaPeopleLine className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Join us</p>
                    <p className="text-sm text-quinary">lets connect</p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          <li className="relative group">
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/contact-us" alt="Contact us page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <BiSolidContact className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Contact us</p>
                    <p className="text-sm text-quinary">get in touch</p>
                  </div>
                </div>
              </Link>
            </button>
          </li>
          </div>
          
          
        </ul>
      </div>
          <div  className="ml-7 mr-8 pb-6">
          <div className=" border border-dotted"></div>
          <div className="border border-dotted"></div>
          <div className="relative group mt-6">
            <button
              type="button"
              onClick={toggleDrawer}
              >
              <Link to="/careers" alt="Join us page link">
                <div className="flex  flex-row items-start rounded-lg bg-transparent p-2 hover:bg-[#444343]  transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-[#ffffff]">
                  <div className="bg-quinary rounded-lg p-[8px]">
                    <MdOutlineElectricalServices className="h-6 w-6" />
                  </div>
                  <div className="ml-3 text-left">
                    <p className="font-semibold">Career</p>
                    <p className="text-sm text-quinary">opportunities</p>
                  </div>
                </div>
              </Link>
            </button>
          </div>

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-6 mt-6 mb-6">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl border ring-1 ring-gray-400">
                <div className="md:flex">
                  <div className="md:shrink-0">
                    <GatsbyImage
                      image={traineeElectrician.childImageSharp.gatsbyImageData}
                      alt={traineeElectrician.name}
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      loading="lazy"
                    />
                  </div>
                  <div className="pl-4 pr-4 mb-4">
                  <div 
                    //className="uppercase text-black-grey-gradient tracking-wide text-sm font-semibold"
                    className={`mt-2 ${styles.headingArticleList}`}
                  >
                    Trainee Electrician
                  </div>
                  <p className="text-quaternary text-sm">
                    We are an ambitious team who are focused on growth, putting
                    our customers at the heart of what we do. We have a great
                    opportunity for a Trainee Electrical Engineer.
                  </p>
                  <span className="block mt-3 text-sm leading-tight text-quinary font-semibold">
                    position closed
                  </span>
                </div>
                </div>
              </div>
              <div className="bg-white rounded-xl shadow-lg overflow-hidden md:max-w-2xl border ring-1 ring-gray-400">
                <div className="md:flex">
                  <div className="md:shrink-0">
                    <GatsbyImage
                      image={electricalEngineer.childImageSharp.gatsbyImageData}
                      alt={electricalEngineer.name}
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      loading="lazy"
                    />
                  </div>
                  <div className="pl-4 pr-4 mb-4">
                    <div 
                      //className="uppercase text-black-grey-gradient tracking-wide text-sm font-semibold"
                      className={`mt-2 ${styles.headingArticleList}`}
                    >
                      Electrical Engineer
                    </div>
                    <p className="text-quaternary text-sm">
                      Committed to ensuring our clients receive exceptional
                      service. Responsible for carrying out a variety of
                      electrical projects from small fixtures to complex
                      installations.
                    </p>
                    <span className="block mt-3 text-sm leading-tight text-quinary font-semibold">
                      position closed
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="border border-dotted"></div>
            <div className="border border-dotted"></div>
          </div>
        </Drawer>
      </div>
    </nav>
  );
};

export default Navbar;
