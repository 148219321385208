const styles = {
  boxWidth: "xl:max-w-[1280px] w-full",

  heading2:
    "font-magistral font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[46.8px] leading-[36.8px] w-full",

  heading3:
    "font-magistral font-semibold text-[22px] xs:text-[22px] ss:text-[34px] md:text-[48px] md:leading-[42.8px] ss:leading-[34px] xs:leading-[22px]  leading-[22px] w-full",

  heading4:
    "font-magistral text-[26px] xx:text-[30px] ss:text-[38px] sm:text-[48px] xx:leading-[38.8px] leading-[30.8px] w-full",

  heading5:
    "font-magistral font-semibold text-[22px] xs:text-[22px] ss:text-[34px] md:text-[48px] md:leading-[42.8px] ss:leading-[34.8px] xs:leading-[22.8px] leading-[22px] w-full",

  headingFeaturedArticle:
    "font-magistral font-semibold text-gray-100 text-[18px] xs:text-[22px] ss:text-[34px] md:text-[48px]  ss:leading-[44.8px] xs:leading-[34.8px] xx:leading-[38.8px] leading-[28px] w-full",

  headingArticleList:
    "font-magistral font-semibold text-black-grey-gradient text-[18px] xs:text-[20px] ss:text-[22px] ss:leading-[44.8px] xs:leading-[34.8px] xx:leading-[38.8px] leading-[28px] w-full",

  headingPopularTopics:
    "font-magistral font-semibold text-black-grey-gradient mt-2 text-[14px] ss:text-[16px] ss:leading-[44.8px] xs:leading-[34.8px] xx:leading-[38.8px] leading-[28px] w-full",

  headingNewsAdvert:
    "font-magistral font-semibold text-[16px] xs:text-[20px] xs:leading-[20.8px] leading-[18px] w-full",

  headingLeadershipProfile:
    "font-magistral font-semibold text-[18px] xs:text-[22px]  md:text-[24px] md:leading-[24.8px]  xs:leading-[22px]  leading-[18px] w-full",

  paragraph:
    "font-magistral font-normal text-white text-[16px] leading-[30.8px]",

  paragraph2:
    "font-magistral font-normal text-quaternary text-[16px] sm:text-[18px] leading-[30.8px]",

  /* paragraphAd:
    "font-magistral font-normal text-quaternary text-[16px] sm:text-[18px] leading-[22.8px]", */

  paragraph3:
    "font-magistral font-normal text-primary text-[16px] sm:text-[18px] leading-[30.8px]",

  paragraph4:
    "font-magistral font-normal text-primary text-[16px] sm:text-[20px] leading-[30.8px]",

  paragraph5:
    "font-magistral text-base text-primary text-[16px] sm:text-[18px] leading-[30.8px]",

  heroExcerpt:
    "font-magistral font-normal mt-6 text-white text-[14px] xx:text-[16px] leading-[30.8px]",

  flexCenter: "flex justify-center items-center",
  flexStart: "flex justify-center items-start",

  paddingX: "sm:px-16 px-6",
  //paddingXSmall: "sm:px-12 px-6",
  paddingY: "sm:py-16 py-6",
  paddingYSmall: "sm:py-12 py-6",
  paddingYNone: "sm:py-4 py-4",
  padding: "sm:px-16 px-6 sm:py-12 py-4",

  marginX: "sm:mx-16 mx-6",
  marginY: "sm:my-16 my-6",
  marginYSmall: "sm:my-8 my-6",

  filtergreen:
    "filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%)",
};

export const layout = {
  section: `flex md:flex-row flex-col ${styles.paddingYSmall}`,
  //sectionNoYPadding: `flex md:flex-row flex-col ${styles.paddingYNone}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingYNone}`,

  sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
  sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-6 relative`,

  sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
};

export default styles;
