//import React from "react";

import { send, shield, star, } from "../images";

// static pages - gallery headers

export const homeGalleryHeader = {
  id: "home-photo-gallery",
  titleTop: "Client gallery",
  titleBottom: "see our work",
  content:
    "No job is too big or small. Intact Electrical are proud to showcase the below images of a few of our commercial and industrial projects we have completed on behalf of our clients.",
};

export const aboutGalleryHeader = {
  id: "about-photo-gallery",
  titleTop: "Team gallery",
  titleBottom: "us at work",
  content:
    "Check out our gallery below, of some of our workforce applying their expertise and craft on-site for our clients. Our team not only has all the gear, but they've got the ideas too.",
};

export const servicesGalleryHeader = {
  id: "about-photo-gallery",
  titleTop: "Services gallery",
  titleBottom: "on display",
  content:
    "At Intact Electrical we love looking back at the results of our hard work and are proud to show images of the services we have completed for our happy clients.",
};

export const projectsGalleryHeader = {
  id: "about-photo-gallery",
  titleTop: "Project gallery",
  titleBottom: "showcase",
  content:
    "Intact Electrical are proud to have been instrumental in realizing the vision for some large corporate organisations. Below are a few images of the work we have completed that delighted those clients.",
};

export const newsGalleryHeader = {
  id: "news-photo-gallery",
  titleTop: "Events gallery",
  titleBottom: "what's new",
  content:
    "Intact Electrical supported Slough in Bloom 2023 enabling this competition to continue for its 29th year. Helping lift the spirits of the people of Slough by encouraging everyone to brighten the town with flowers.",
};

// Dynamic services gallery headers

export const electricalInstallationsGalleryHeader = {
  id: "electrical-installations-gallery-header",
  titleTop: "Installations gallery",
  titleBottom: "looking good",
  content:
    "Intact Electrical take professional pride in completing all our installation projects to industry safety standards, first time every time. We also recognize the importance of aesthetics.",
};

export const eicrPatGalleryHeader = {
  id: "eicr-gallery",
  titleTop: "Testing gallery",
  titleBottom: "safe & sound",
  content:
    "Intact Electrical take health and safety seriously and encourages all our clients to do the same. Regular testing avoids fines and prosecution, but we also feel we’re helping to prevent injuries and casualties.",
};

export const wiringServicesGalleryHeader = {
  id: "wiring-services-gallery",
  titleTop: "Wiring gallery",
  titleBottom: "neat & tidy",
  content:
    "Intact Electrical have undertaken multiple wiring projects for our domestic, commercial and industrial clients, we are meticulous in our work, even though ultimately it will be behind the scenes.",
};

export const lightingSolutionsGalleryHeader = {
  id: "lighting-services-gallery",
  titleTop: "Lighting gallery",
  titleBottom: "let it shine",
  content:
    "Take a look at Intact Electrical, who are full of bright ideas about how to create the perfect lighting solution to realise your vision, be it functional, attention seeking or ambient.",
};

export const fuseboardServicesGalleryHeader = {
  id: "fuseboard-services-gallery",
  titleTop: "Fuse board gallery",
  titleBottom: "safety assured",
  content:
    "Intact Electrical always adhere to our own high standards, and industry best practices, you can trust our qualified team to safely poke around with your consumer unit, as you can see in the pictures.",
};

export const securitySolutionsGalleryHeader = {
  id: "security-solutions-services-gallery",
  titleTop: "Security gallery",
  titleBottom: "deter & protect",
  content:
    "Trust Intact Electrical to help you take care of yours, check out our images of our professional installation of unobtrusive and effective electrical security solutions.",
};

// Dynamic projects gallery headers

export const dhlProjectGalleryHeader = {
  id: "dhl-project-gallery",
  titleTop: "DHL",
  titleBottom: "project gallery",
  content:
    "Intact Electrical simply delivered for DHL. On-brand, on-time, and on-budget. See the images below showcasing how we helped make their expectation become reality.",
};

export const ladbrokesProjectGalleryHeader = {
  id: "ladbrokes-project-gallery",
  titleTop: "Ladbrokes",
  titleBottom: "project gallery",
  content:
    "Intact Electrical were a safe bet for Ladbrokes. On-brand, on-time, and on-budget. See the images below illustrating how we helped make their expectation become reality.",
};

export const medivetProjectGalleryHeader = {
  id: "medivet-project-gallery",
  titleTop: "Medivet",
  titleBottom: "project gallery",
  content:
    "Intact Electrical took the ultimate care for Medivet. On-brand, on-time, and on-budget. See the images below illuminating how we helped make their expectation become reality.",
};

export const biffaProjectGalleryHeader = {
  id: "biffa-project-gallery",
  titleTop: "Biffa",
  titleBottom: "project gallery",
  content:
    "Intact Electrical had no time to waste for Biffa. On-brand, on-time, and on-budget. See the images below expressing how we helped make their expectation become reality.",
};
export const harveyNicholsProjectGalleryHeader = {
  id: "harvey-nichols-project-gallery",
  titleTop: "Harvey Nichols",
  titleBottom: "project gallery",
  content:
    "Intact Electrical provided gourmet service to Harvey Nichols. On-brand, on-time, and on-budget. See the images below showing how we helped make their expectation become reality.",
};

export const theHubProjectGalleryHeader = {
  id: "the-hub-project-gallery",
  titleTop: "The Hub",
  titleBottom: "project gallery",
  content:
    "Intact Electrical were flying high at The Hub. On-brand, on-time, and on-budget. See the images below expressing how we helped make their expectation become reality.",
};

export const domesticInstallationGalleryHeader = {
  id: "domestic-installation-gallery",
  titleTop: "Domestic installation",
  titleBottom: "project gallery",
  content:
    "Our client is great at DIY, recognising that making good with paint and filler is one thing, but they trust Intact Electrical for their electrical work, to avoid kabooms.",
};

// dynamic article gallery headers

export const burnhamJuniorGalleryHeader = {
  id: "burnhan-junior-article-gallery",
  titleTop: "Burnham Junior FC",
  titleBottom: "gallery",
  content:
    "Intact Electrical are proud sponsors of our local under 15 youth football team Burnham Junior FC. Take a look at the images below showcasing the local talent in action.",
};

export const burnhamFcGalleryHeader = {
  id: "burnhan-fc-article-gallery",
  titleTop: "Burnham FC",
  titleBottom: "gallery",
  content:
    "After that season and the club finding a new sponsor for the first team shirts, we instead became sponsors of the Burnham FC u18s and retained our pitch side and car park banners.",
};

export const sloughInBloomGalleryHeader = {
  id: "slough-in-bloom-article-gallery",
  titleTop: "Slough in Bloom",
  titleBottom: "gallery",
  content:
    "Intact Electrical are proud of our contribution to growing a brighter local community by sponsoring Slough in Bloom 2023 competition. Take a ramble through our gallery of this year's overall and a few of the gold winners below.",
};

export const hsPepperGalleryHeader = {
  id: "hs-pepper-article-gallery",
  titleTop: "H&S Pepper Co",
  titleBottom: "gallery",
  content:
    "Intact Electrical are proud of our collaboration with this fabulous local business created during covid times, we set up their lighting and temp system, be careful this gallery is hot stuff.",
};

export const footballCoachGalleryHeader = {
  id: "football-coach-article-gallery",
  titleTop: "Richard's",
  titleBottom: "coaching gallery",
  content:
    "I’m proud of the images of me coaching and helping to make winners on the pitch. Its hard work, but so satisfying to help the lads improve and have fun.",
};

export const motorcycleRacerGalleryHeader = {
  id: "motorcycle-racer-article-gallery",
  titleTop: "Gavin's",
  titleBottom: "racing gallery",
  content:
    "I'm proud of the images of me pursuing my dream. Hard work and commitment enabled me to excel, with good friends made along the way.",
};

export const herschelGrammarGalleryHeader = {
  id: "herschel-grammar-article-gallery",
  titleTop: "Herschel Grammar",
  titleBottom: "gallery",
  content:
    "We are proud of the ongoing service we give to Herschel and we’re sure they appreciate what we do as they always have a cup of tea or coffee with biscuits ready for us.",
};

export const pioneerTrustGalleryHeader = {
  id: "pioneer-trust-article-gallery",
  titleTop: "Pioneer Trust",
  titleBottom: "gallery",
  content:
    "We’re proud to have extended our network of School maintenance contracts with Pioneer Trust. See below some of the works we have completed for them.",
};

export const scrRacingGalleryHeader = {
  id: "scr-racing-article-gallery",
  titleTop: "SCR 31 BSB",
  titleBottom: "gallery",
  content:
    "It was a joy for Intact Electrical to be part of the SCR team and help them on their journey, supporting their aspirations of crossing the finish line in the winner’s circle.",
};

// Featured Content data for dynamic services pages

export const electricalInstallationsFeaturedContent = {
  id: "electrical-installations-featured-content",
  titleTop: "Installations",
  titleBottom: "for all sectors",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Intact Electrical are an industry accredited electrical contractor in Buckinghamshire providing electrical installation services for a wide range of domestic, commercial, industrial, medical and hospitality settings throughout the South of England.",
  content2:
    "Sectors include homes, offices, retail, warehouses, schools, medical, hotels etc. Our services are tailored to meet the specific needs and demands of each individual client, always striving to achieve 100% customer satisfaction.",
};

export const eicReportsPatTestingFeaturedContent = {
  id: "eic-report-pat-testing-featured-content",
  titleTop: "EICR & PAT",
  titleBottom: "safety testing",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Intact Electrical are NICEIC approved contractors, and health and safety is at the top of our minds. Proper installation practices including testing, ensures when the project is complete, its future proofed for our clients. ",
  content2:
    "We also provide standalone testing services to our domestic, commercial and industrial clients on their electrical installations and appliances respectively, regardless of our involvement in any prior installation.",
};

export const wiringServicesFeaturedContent = {
  id: "wiring-services-featured-content",
  titleTop: "Wiring services",
  titleBottom: "for all sectors",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "There could be many reasons for a need to upgrade your electrical wiring completely or partially, at home or workplace. You might be planning a major office or warehouse refurb, or perhaps you're converting a part of your home.",
  content2:
    "If you have safety concerns about your electrical wiring, you should first consider contacting us to arrange an EICR to identify risk areas, before resorting to a complete or partial rewiring of your property. No job is too big or small.",
};

export const LightingServicesFeaturedContent = {
  id: "lighting-services-featured-content",
  titleTop: "Lighting solutions",
  titleBottom: "for all areas",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Lighting requirements can vary depending on environment, preference and purpose. We can provide and install lighting solutions within all sectors ensuring the results make our clients' expectations become reality.",
  content2:
    "If reducing your carbon footprint is top of mind, you may want to convert to LED and/or smart controlled lighting to reduce energy consumption and cost. External lighting can also enhance or secure your property.",
};

export const fuseboardServicesFeaturedContent = {
  id: "fuseboard-services-featured-content",
  titleTop: "Fuse boards",
  titleBottom: "upgrades & testing",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Your electrical fuse board may be in poor condition or not up to industry standards. We can upgrade fuse boards in all sectors to eliminate problems and keep your electrical circuits safe and compliant.",
  content2:
    "We can also test your fuse boards to ensure that they comply with current safety regulations and are fit for purpose. Our team are NICEIC approved contractors and domestic installers, so be confident you are in safe hands.",
};

export const securitySolutionsFeaturedContent = {
  id: "cctv-services-featured-content",
  titleTop: "Security solutions",
  titleBottom: "peace of mind",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "In today’s world it’s sadly becoming increasingly important to have layers of security protection for homes and commercial premises to protect our families, property, business and employees.",
  content2:
    "Intact Electrical can assist with helping you secure what you care about the most, from installing security lighting and CCTV, to electric gates and access systems, or rollers and shutters.",
};

// Featured Content data for dynamic projects pages

export const dhlFeaturedContent = {
  id: "dhl-featured-content",
  titleTop: "DHL Reading",
  titleBottom: "simply delivered",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Intact Electrical were chosen as the preferred and trusted electrical contractor by Woodhouse Workspace as they strived to deliver DHL's new Reading distribution hub Cat B fit out to the client's requirements.",
  content2:
    "Our project included installation of high bay warehouse lighting, small power such as sockets, controls and roller shutters, additional fuse boards, heating and conveyor belt power, floodlights, vehicle charging points and bell systems.",
};

export const ladbrokesFeaturedContent = {
  id: "ladbrokes-featured-content",
  titleTop: "Ladbrokes Woking",
  titleBottom: "a safe bet",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Intact Electrical were chosen as the preferred and trusted electrical contractor by Woodhouse Workspace as they strived to transform Ladbrokes new Woking office space Cat B fit out to the client's requirements.",
  content2:
    "Our project included installation of both linear and feature lighting, small power such as sockets and feeds for air con and door access. New fuse boards, UPS battery backup system and power for appliances in the breakout areas.",
};

export const medivetFeaturedContent = {
  id: "medivet-featured-content",
  titleTop: "Medivet Watford",
  titleBottom: "the ultimate care",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Intact Electrical were chosen as the preferred and trusted electrical contractor by Woodhouse Workspace as they strived to transform the Medivet new Watford office space Cat B fit out to the client's requirements.",
  content2:
    "Our project included the installation of lighting, power to tea points, floor boxes with both data and power points, and installation of new comms cabinet data.",
};

export const biffaFeaturedContent = {
  id: "biffa-featured-content",
  titleTop: "Biffa workshop",
  titleBottom: "no time to waste",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Starting over 100 years ago, waste management simply meant taking it away. Today, Biffa helps organisations manage their waste sustainably and cost effectively.",
  content2:
    "Biffa turned to Intact Electrical with the task of designing and installing a new fully operational 3 phase electrical supply system to their brand new workshop at one of their sites in Farnham.",
};

export const harveyNicholsFeaturedContent = {
  id: "harvey-nichols-featured-content",
  titleTop: "Harvey Nichols",
  titleBottom: "gourmet service",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Founded in 1831, Harvey Nichols is internationally renowned for its expertly edited fashion and beauty merchandise, premium food and wine offer and award-winning restaurants.",
  content2:
    "Intact Electrical were chosen as the preferred service provider for the chiller upgrade project at their flagship Knightsbridge store, liaising with National Grid personnel for onsite connectivity.",
};

export const theHubFeaturedContent = {
  id: "the-hub-featured-content",
  titleTop: "The Hub",
  titleBottom: "flying high",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "This award-winning 1930s building was originally an airport departure lounge and control tower. Completely restored and refurbished to provide contemporary office accommodation.",
  content2:
    "Intact Electrical were chosen as the preferred and trusted electrical installation experts for The Hub’s internal and external commercial office lighting refurbishments.",
};

export const domesticInstallationFeaturedContent = {
  id: "domestic-installation-featured-content",
  titleTop: "Household policy",
  titleBottom: "no visible wires",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "At Intact Electrical our friendly team are experienced NICEIC approved domestic installers. We were called by local residents who were renovating the top floor of their town house.",
  content2:
    "Adept at decorating and finishing, however this client consults the experts for all their electrical requirements after a Kaboom moment with a power point a few years ago.",
};

// Featured Content data for articles

export const burnhamJuniorFeaturedContent = {
  id: "burnham-under-15s-featured-content",
  titleTop: "Community first",
  titleBottom: "u15 football team",
  buttonLabel: "Join us",
  link: "/join-us",
  content1:
    "We have been proud sponsors of Burnham Junior FC since 2019. The pride we felt the first time we watched the under 11s from our neighbourhood donning the kit with our branding is something that will stay with us forever.",
  content2:
    "We've kept the momentum, currently sponsoring the under 15s team in 2023. We remember where we started and hope to inspire the next generation on the way.",
};

export const burnhamFcFeaturedContent = {
  id: "burnhan-fc-featured-content",
  titleTop: "Community",
  titleBottom: "focal point",
  buttonLabel: "Join us",
  link: "/join-us",
  content1:
    "Founded in 1878, Burnham Football Club is in the Combined Counties Football League Premier Division North (CCLPN). They play all home games at the 2,500 capacity 1878 stadium in Burnham Buckinghamshire.",
  content2:
    "Their ambition is to be an all-inclusive community focal point for football, regardless of gender or ability. Their focus is to progress through the leagues and divisions to reinstate the club’s semi-professional status.",
};

export const sloughInBloomFeaturedContent = {
  id: "slough-in-bloom-featured-content",
  titleTop: "Slough In Bloom",
  titleBottom: "2023",
  buttonLabel: "Join us",
  link: "/join-us",
  content1:
    "This year’s Slough in Bloom gardens and allotments competition was launched, Bruce Hicks (Senior Parks Improvement Officer) said “Slough in Bloom have been working with new sponsors who have enabled this lovely competition to continue for its 29th year.",
  content2:
    "Intact Electrical are proud to be part of that sponsorship support for the competition, helping lift the spirits of local people who live in and visit Slough by encouraging everyone to brighten the town with flowers.",
};

export const hsPeppersFeaturedContent = {
  id: "hs-peppers-featured-content",
  titleTop: "Homegrown",
  titleBottom: "goodness",
  buttonLabel: "Join us",
  link: "/join-us",
  content1:
    "H&S Pepper Co is a local start up. The idea was born in Covid lockdown, when they took their hobby to a new dimension. As lovers of hot food with family traditions that included homemade chilli sauces.",
  content2:
    "Chilli plants can be temperamental, and difficult to grow. The really hot varieties don’t usually fare well in the UK, as they prefer a much longer and hotter summer season that our weather supports.",
};

export const eicrLandlordsFeaturedContent = {
  id: "eicr-landlords-featured-content",
  titleTop: "EICR",
  titleBottom: "safety testing",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "An EICR is a test of the fixed wiring in a property (anything electrical that can't be unplugged) to ensure your electrical installation is up to standard and meets all safety requirements.",
  content2:
    "As registered and approved electrical contractors Intact Electrical can test your electrical installations to ensure the correct procedures have been applied in your electrical wiring.",
};

export const footballCoachFeaturedContent = {
  id: "football-coach-featured-content",
  titleTop: "Richard Barber",
  titleBottom: "football coach",
  buttonLabel: "About us",
  link: "/about-us",
  content1:
    "Wow what a journey! I'm still not quite sure how it happened. It started with me as a proud parent watching his kids playing for local youth teams at Burnham Junior Football Club.",
  content2:
    "Now I have successfully completed my FA coaching badges and become both head coach for my son's team and assistant coach for my daughter's team.",
};

export const motorcycleRacerFeaturedContent = {
  id: "motorcycle-racer-featured-content",
  titleTop: "Gavin Little",
  titleBottom: "racer at heart",
  buttonLabel: "About us",
  link: "/about-us",
  content1:
    "It was a good few years ago, but I remember it like it was yesterday. I was sitting on the bank at Brands Hatch with my friends watching a motorbike race start.",
  content2:
    "The atmosphere was electric. The buzz of the crowd, the noise, the excitement, I was hooked. I turned to my friends and said “I want to do this! I am going to start racing!”",
};

export const herschelGrammarFeaturedContent = {
  id: "herschel-grammar-featured-content",
  titleTop: "Herschel Grammar",
  titleBottom: "school contract",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Herschel Grammar School is a co-educational grammar school with academy status, located in Slough, Berkshire. The school has around 900 pupils.",
  content2:
    "Herschel has a warm and vibrant atmosphere built on an ethos of mutual trust, high academic standards and a commitment to nurturing and developing every individual pupil.",
};

export const pioneerTrustFeaturedContent = {
  id: "pioneer-trust-featured-content",
  titleTop: "Pioneer Trust",
  titleBottom: "school contract",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Pioneer Education Trust incorporates Desborough College, Foxborough Primary School, Trevelyan Middle School and Upton Court Grammar School.",
  content2:
    "Founded in 2014, Pioneer is now a growing family of schools based in Berkshire. They are a values-led, evidence-informed organisation, driven to invest intelligently in their people.",
};

export const scrRacingFeaturedContent = {
  id: "scr-racing-featured-content",
  titleTop: "About",
  titleBottom: "Sam Cox",
  buttonLabel: "Join us",
  link: "/join-us",
  content1:
    "Sam started riding motorcycles just after his 12th Birthday in 2002.  He was put on a CB 125 to learn clutch control and gear changes at Lee Valley Cycle Club car park, (which is now the Olympic Velodrome site).",
  content2:
    "It was on a Suzuki 650 Minitwin that Sam entered the MRO Minitwin Championship in 2009 to begin serious racing. The next three years were a rollercoaster ride of race wins, lap records, crashes and broken bones.",
};

// Featured Content data for primary pages

export const homeFeaturedContent = {
  id: "featured-home-content",
  titleTop: "Let us handle",
  titleBottom: "your next project",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Do you require industrial, commercial, or domestic electrical services? Considering a new installation, rip and replace, at the planning stage?  Or you're just not sure. Get in touch today and we can advise you.",
  content2:
    "No matter what your electrical requirements, Intact Electrical have you covered. With over 20 years' experience and a team dedicated to customer satisfaction you won't be disappointed.",
};

export const aboutFeaturedContent = {
  id: "featured-about-content",
  titleTop: "Our expectation",
  titleBottom: "became reality",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Over the years we worked together on numerous electrical projects for our employer and were often described as victims of our own success. Underappreciated, our reliability and work ethic were taken for granted.",
  content2:
    "We discovered our goals and aspirations were similar, and our expectation became reality when Intact Electrical Ltd was born in October 2016, and we've never looked back.",
};

export const serviceFeaturedContent = {
  id: "featured-services-content",
  titleTop: "Look no further",
  titleBottom: "call us today",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Industrial, commercial, or domestic, large or small, whatever electrical services you require, Intact Electrical treats every single job with the same priority and professionalism.",
  content2:
    "Take a look at some of the services we have provided for clients in our community and for major building contractors around the UK.",
};

export const projectFeaturedContent = {
  id: "featured-project-content",
  titleTop: "Why our clients",
  titleBottom: "trust us",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "We believe it is critical to listen and understand the client's vision before embarking on any project. We have the knowledge and experience to formulate a solution to ultimately make our clients' expectations become reality.",
  content2:
    "Comfortable working in an extended team environment, we use a methodical approach to advise our clients and other project stakeholders on risk assessment and mitigation.",
};

export const newsFeaturedArticleContent = {
  id: "featured-article-news-content",
  titleTop: "Featured project",
  titleBottom: "Medivet",
  buttonLabel: "Read more",
  link: "/projects/medivet-watford",
  content1:
    "Founded in 1987, Medivet is a veterinary owned and led group of more than 400 branches with 27 state-of-the art, 24-hour veterinary centers across the UK.",
  content2:
    "To support their rapid growth Medivet has made significant investments in new systems and infrastructure, to enable them to continue to deliver exceptional care for pets.",
};

export const partnershipFeaturedContent = {
  id: "partnership-featured-content",
  titleTop: "Putting our",
  titleBottom: "community first",
  buttonLabel: "Join us",
  link: "/join-us",
  content1:
    "Intact Electrical are more than just an electrical installation business, we care passionately about making a valuable contribution to the greater good of the local area.",
  content2:
    "We’re constantly on the lookout for intuitive ways to collaborate and support each other via events, online, articles and newsletters. Bringing our community together.",
};

export const joinUsFeaturedContent = {
  id: "join-us-featured-content",
  titleTop: "Collaborate",
  titleBottom: "partner with us",
  buttonLabel: "About us",
  link: "/about-us",
  content1:
    "Intact Electrical are proud of our reputation for excellence, based on our satisfied clients whose referrals have enabled us to grow our business into what it is today.",
  content2:
    "We also take a holistic view on what is important, beyond just electricals, and are consciously expanding our network to collaborate with other likeminded organisations.",
};

export const contactUsFeaturedContent = {
  id: "contact-us-featured-content",
  titleTop: "Lets connect",
  titleBottom: "& grow together",
  buttonLabel: "About us",
  link: "/about-us",
  content1:
    "Intact Electrical are keen to stay up to date and keep in touch with you, our contacts, clients and partners. Social media has made it easier for us all to provide real time updates on our latest news.",
  content2:
    "Extend your network by joining our social media community. You can find us on Facebook, Instagram, and LinkedIn via the logo links on the header and footer.",
};

export const accreditationsFeaturedContent = {
  id: "accreditations-featured-content",
  titleTop: "Industry approved",
  titleBottom: "certifications",
  buttonLabel: "About us",
  link: "/about-us",
  content1:
    "The field of electrical work is an essential and ever-evolving industry that plays a vital role in our modern society. Electricians are responsible for installing, repairing, and maintaining electrical systems in domestic, commercial, and industrial environments.",
  content2:
    "We continuously invest in training and certifications for our team. Keeping up to date with new technologies, techniques, and regulations is crucial for our professional growth.",
};

export const careersFeaturedContent = {
  id: "careers-featured-content",
  titleTop: "We help our",
  titleBottom: "employees thrive",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "Looking to start your new career as an electrician? Under our guidance, you’ll benefit from all the training necessary to become a fully qualified installation engineer.",
  content2:
    "Are you a qualified Electrician? Consider joining our collaborative team. We are a leading electrical contractor with highly varied projects in all sectors, offering competitive salaries.",
};

export const helpCenterFeaturedContent = {
  id: "help-centre-featured-content",
  titleTop: "Trust us",
  titleBottom: "ask the experts",
  buttonLabel: "Contact us",
  link: "/contact-us",
  content1:
    "With decades of experience in domestic, commercial and industrial electrical installations, Intact Electrical are best placed to answer your questions.",
  content2:
    "You might not be completely certain about what your electrical requirements are. Contact the experts at Intact Electrical, we will hep you find the best solution for your requirements.",
};

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "about-us",
    title: "About Us",
  },
  {
    id: "services",
    title: "Services",
  },
  {
    id: "projects",
    title: "Projects",
  },

  {
    id: "clients",
    title: "Clients",
  },
  {
    id: "articles",
    title: "Articles",
  },
  {
    id: "contact-us",
    title: "Contact Us",
  },
];

// Trusted services

export const trustedServices = {
  id: "features-content",
  titleTop: "Electrical services",
  titleBottom: "you can trust",
  buttonLabel: "Services",
  link: "/services",
  content1:
    "Intact Electrical Ltd are reliable, professional, registered and approved electrical contractors and installers, with decades of invaluable knowledge. We provide every client with quality services and an exceptional customer experience.",
  content2:
    "We pride ourselves on our workmanship, which is the lasting mark of any electrical contractor, our name and reputation depend on it. You can trust Intact Electrical to make your expectation become reality.",
};

// Features in trusted services

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Fully Certified",
    content:
      "Our workforce at Intact Electrical are fully certified electrical engineers with up-to-date training and certifications.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Fully Licensed & Insured",
    content:
      "A fully insured electrical engineering business, protecting our clients and ourselves during and after services provided.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Customer Satisfaction",
    content:
      "At Intact Electrical we take pride on our efficient and friendly service, striving to achieve 100% customer satisfaction.",
  },
];

// hero details for primary pages

export const aboutUsHeroDetails = {
  id: "about-hero-details",
  titleTop: "Intact Electrical",
  titleBottom: "About us",
  excerpt:
    "Meet our leadership team and talented people. See who we are, and why we should be your first point of call.",
  buttonLeft: "Services",
  buttonLeftLink: "/services",
  buttonRight: "Partnerships",
  buttonRightLink: "/partners",
};

export const servicesHeroDetails = {
  id: "services-hero-details",
  titleTop: "Intact Electrical",
  titleBottom: "Services",
  excerpt:
    "No matter how big or small a job may be, we are dedicated to providing a first-class professional service every time.",
  buttonLeft: "Projects",
  buttonLeftLink: "/projects",
  buttonRight: "About us",
  buttonRightLink: "/about-us",
};

export const projectsHeroDetails = {
  id: "projects-hero-details",
  titleTop: "Intact Electrical",
  titleBottom: "Projects",
  excerpt:
    "We are meticulous in completing projects that leave our clients delighted, believing you're only as good as your last job.",
  buttonLeft: "News",
  buttonLeftLink: "/news",
  buttonRight: "Partnerships",
  buttonRightLink: "/partners",
};

export const latestNewsHeroDetails = {
  id: "latest-news-hero-details",
  titleTop: "Intact Electrical",
  titleBottom: "News",
  excerpt:
    "Keep up to date with us. Meet our clients, and our local partner organisations. Read our views on what's important to us.",
  buttonLeft: "Services",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

export const partnershipsHeroDetails = {
  id: "partnerships-hero-details",
  titleTop: "Intact Electrical",
  titleBottom: "Partners",
  excerpt:
    "We actively partner and collaborate with local organisations, helping build and grow a stronger community together.",
  buttonLeft: "Services",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

export const BecomeAPartnerHeroDetails = {
  id: "join-us-hero-details",
  titleTop: "Intact Electrical",
  titleBottom: "Join us",
  excerpt:
    "We want to hear from you if you share our commitment and are keen to be involved in joint initiatives for mutual benefit.",
  buttonLeft: "Services",
  buttonLeftLink: "/services",
  buttonRight: "News",
  buttonRightLink: "/news",
};

export const contactUsHeroDetails = {
  id: "contact-us-details",
  titleTop: "Intact Electrical",
  titleBottom: "Contact us",
  excerpt:
    "Reach out to us for all your electrical installation enquiries via email, phone or our webform. Follow us on social media.",
  buttonLeft: "Sevices",
  buttonLeftLink: "/services",
  buttonRight: "News",
  buttonRightLink: "/news",
};

export const accreditationsHeroDetails = {
  id: "accreditations-details",
  titleTop: "Intact Electrical",
  titleBottom: "Accreditations",
  excerpt:
    "Intact Electrical fully trains all our staff and ensures we are up to date with industry standard registrations and certifications.",
  buttonLeft: "Sevices",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

export const careersHeroDetails = {
  id: "careers-details",
  titleTop: "Intact Electrical",
  titleBottom: "Careers",
  excerpt:
    "Intact Electrical fully trains all our staff and ensures we are up to date with industry standard registrations and certifications.",
  buttonLeft: "Sevices",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

export const faqsHeroDetails = {
  id: "help-centre-details",
  titleTop: "Intact Electrical",
  titleBottom: "Help center",
  excerpt:
    "As experienced electrical installation experts, we hope to be able to answer your frequently asked questions.",
  buttonLeft: "Sevices",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

export const termsHeroDetails = {
  id: "terms-details",
  titleTop: "Intact Electrical",
  titleBottom: "Terms & conditions",
  excerpt:
    "Please ensure you review our policy terms. By browsing our website, we assume you will adhere to the conditions.",
  buttonLeft: "Sevices",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

export const privacyHeroDetails = {
  id: "privacy-details",
  titleTop: "Intact Electrical",
  titleBottom: "Privacy policy",
  excerpt:
    "We ensure we meet all our obligations regarding the protection of end user data privacy.",
  buttonLeft: "Sevices",
  buttonLeftLink: "/services",
  buttonRight: "Projects",
  buttonRightLink: "/projects",
};

// Home page advert details collection

export const adverts = [
  {
    id: "domestic-electrical-services",
    titleTop: "Domestic",
    titleBottom: "electrical services",
    buttonLabel: "Fuse boards",
    link: "/services/fuse-boards",
    imgLeft: true,
    content1:
      "Intact Electrical provide comprehensive domestic electrical services. Our staff are friendly and polite showing respect for your home. No matter how simple or complex your requirements are, we can help. From sockets, switches and lighting systems, to new fuseboards, rewiring and connecting you to or from the electrical grid.",
    content2:
      "Need us to troubleshoot an electrical fault? Or interested in extending your electrical capacity to your garden shed or external lighting? Perhaps you are considering a security system or CCTV. If so, get in touch, no job is too big or small.",
  },
  {
    id: "commercial-electrical-services",
    titleTop: "Commercial",
    titleBottom: "electrical services",
    buttonLabel: "Lighting solutions",
    link: "/services/lighting-services",
    imgLeft: false,
    content1:
      "Intact Electrical are well prepared and equipped to carry out all types of commercial work quickly and efficiently. Adhering to the ethos and vision of the main client, always aiming to achieve 100% customer satisfaction with a prompt and friendly service.",
    content2:
      "We have the necessary knowledge and expertise to carry out inspection and testing of wiring in a commercial environment, in accordance with current regulations. After a full assessment of existing installations, we can provide a comprehensive inspection report, enabling efficient planning of proposed future work.",
  },
  {
    id: "training-and-certifications",
    titleTop: "Training & electrical",
    titleBottom: "certifications",
    buttonLabel: "Accreditations",
    link: "/accreditations",
    imgLeft: true,
    content1:
      "Intact Electrical are fully insured professional electrical installers. In addition to 20+ years of experience, our accreditations include: NICEIC Approved Contractor & Domestic Installer, IPAF Accredited, IET 18th Edition Electricians, NVQ  3, Part P Registered, CHAS Accredited, SSIP Registered.",
    content2:
      "We continuously invest in training for our staff. Health & Safety is at the front of our minds. Electricity can be dangerous, so adequate skills and preparation prior to any project is critical. Proper installation practices ensure the completed project is future proofed.",
  },
];

// Partnerships page advert details collection

export const SponsorAdverts = [
  {
    id: "burnham-junior-fc",
    titleTop: "Our pride",
    titleBottom: "& joy",
    buttonLabel: "Read More",
    link: "/articles/burnham-junior-fc",
    imgLeft: true,
    content1:
      "As keen football fans we have sponsored local youth teams at Burnham Junior FC since 2019. The pride we felt the first time we watched the youngsters from our neighbourhood, donning the kit with Intact Electrical branding is something that will stay with us forever.",
    content2:
      "Intact Electrical are extending our support again this year to keep the momentum. Proudly sponsoring the Burnham Junior FC under 15 team in the 2023/24 season. We remember where we started and hope to inspire the next generation on the way.",
  },
  {
    id: "slough-in-bloom",
    titleTop: "Brighten up",
    titleBottom: "the town",
    buttonLabel: "Read More",
    link: "/articles/slough-in-bloom",
    imgLeft: false,
    content1:
      "Slough in Bloom annual gardens and allotments competition was launched in July. Bruce Hicks (Senior Parks Improvement Officer) said “Slough in Bloom have been working with new sponsors who have enabled this lovely competition to continue for its 29th year”.",
    content2:
      "Intact Electrical are proud to be part of that sponsorship support for the 2023 Slough in Bloom competition. Putting our local community first and helping lift the spirits of people who live in and visit Slough. By encouraging everyone to grow beautiful flowers that brighten up the town with colour, it gives us all a place to be proud of.",
  },
  {
    id: "hs-pepper-co",
    titleTop: "Homegrown",
    titleBottom: "goodness",
    buttonLabel: "Read More",
    link: "/articles/hs-pepper-co",
    imgLeft: true,
    content1:
      "H&S Pepper Co is a local business that produce home-grown pesticide free chilli-based products including sauces and pickles which they sell online to local business, family, and friends in the community. A brand-new local startup, already working with a couple of our major local clients.",
    content2:
      "After providing domestic electrical services to the directors of H&S Pepper Co, we started to build a relationship with them, and saw this as the perfect opportunity to connect with another local business to help their cause and ours.",
  },
];

// Footer links

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "About us",
        link: "/about-us",
      },
      {
        name: "Services",
        link: "/services",
      },
      {
        name: "Projects",
        link: "/projects",
      },
      {
        name: "News",
        link: "/news",
      },
    ],
  },
  {
    title: "Operations",
    links: [
      {
        name: "Contact us",
        link: "/contact-us",
      },
      {
        name: "Help center",
        link: "/help-centre",
      },
      {
        name: "Terms & conditions",
        link: "/terms-and-conditions",
      },
      {
        name: "Privacy policy",
        link: "/privacy-policy",
      },
      {
        name: "Accreditations",
        link: "/accreditations",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Partnerships",
        link: "/partners",
      },
      {
        name: "Join us",
        link: "/join-us",
      },
      {
        name: "Careers",
        link: "/careers",
      },
      {
        name: "Latest event",
        link: "/articles/slough-in-bloom",
      },
    ],
  },
];

// Social media links

/* export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/intactelectricalltd/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/intactelectrics",
  },
  {
    id: "social-media-3",
    icon: linkedin,
    link: "https://uk.linkedin.com/company/intact-electrical-limited",
  },
]; */

// Intact Electrical Project Headers and content

export const ladbrokesProjectHeader = {
  id: "ladbrokes-project-header",
  titleTop: "Ladbrokes Woking",
  titleBottom: "a safe bet",
  link: "/projects/ladbrokes-woking",
  content:
    "Intact Electrical were the preferred service provider to Woodhouse Workspace for a full electrical Cat B fit out for betting chain Ladbrokes new offices in Woking.",
};

export const ladbrokeProjectDetails = [
  {
    id: "ladbrokes-1",
    title: "The walkthrough",
    content:
      "The office space had been stripped back to a basic shell. We were presented with the client's vision and requirements at a site meeting, enabling us to prepare a detailed tender.",
  },
  {
    id: "ladbrokes-2",
    title: "Planning phase",
    content:
      "After preparing all the cable calculations and drawings, we compiled the project RAMS. Materials were ordered, and inductions booked in to ensure site safety.",
  },
  {
    id: "ladbrokes-3",
    title: "Joint execution",
    content:
      "We presented weekly toolbox talks, where we would widely discuss relevant safety issues that may arise during the project and how they could be prevented.",
  },
  {
    id: "ladbrokes-4",
    title: "Realising expectations",
    content:
      "After project completion we tested, commissioned and labelled the installation. We then compiled and issued the O&M's including certificates, drawings and data sheets.",
  },
];

export const medivetProjectHeader = {
  id: "medivet-project-header",
  titleTop: "Medivet Watford",
  titleBottom: "the ultimate care",
  link: "/projects/medivet-watford",
  content:
    "Intact Electrical are proud to be part of the Medivet Project. Working under the Woodhouse Workspace umbrella, as the stakeholder for electrical installations for lighting and power solutions.",
};

export const medivetProjectDetails = [
  {
    id: "medivet-1",
    title: "The walkthrough",
    content:
      "This important preparation stage brings multiple stakeholders together, enabling understanding of the overall vision, design and client expectations.",
  },
  {
    id: "medivet-2",
    title: "Planning phase",
    content:
      "After mapping out the light and dark spots in the internal structure, we then performed the necessary tests to achieve optimum positioning for ambient lighting.",
  },
  {
    id: "medivet-3",
    title: "Joint execution",
    content:
      "Our team worked seamlessly with the other stakeholders to align on critical project milestone deadlines enabling us to complete on time, on brand and on budget.",
  },
  {
    id: "medivet-4",
    title: "Realising expectations",
    content:
      "The professionalism of our experienced workforce allowed us to achieve the overall vision, lighting a modern stylish workspace allowing our client's employees to shine.",
  },
];

export const dhlProjectHeader = {
  id: "dhl-project-header",
  titleTop: "DHL Reading",
  titleBottom: "simply delivered",
  link: "/projects/dhl-reading",
  content:
    "We are proud to be part of DHL’s project for their new distribution hub in Reading. Working with Woodhouse Workspace, as the stakeholder for a comprehensive electrical Cat B fit out.",
};

export const dhlProjectDetails = [
  {
    id: "dhl-1",
    title: "The walkthrough",
    content:
      "Site visit of the clean slate Cat A fit out completion. Alignment with the ethos of other stakeholders and client vision for Cat B. Light measurements across the building.",
  },
  {
    id: "dhl-2",
    title: "Planning phase",
    content:
      "Completion of RAMS (Risk Assessment & Method Statements) & technical drawings. RAMS help identify risks that could occur during the project and how we aim to mitigate.",
  },
  {
    id: "dhl-3",
    title: "Joint execution",
    content:
      "Weekly site meetings with DHL and all stakeholders. After our work was complete, we carried out extensive testing, commissioning, and labeling of the electrical installation.",
  },
  {
    id: "dhl-4",
    title: "Realising expectations",
    content:
      "Once the project was snagged and completed to DHL satisfaction, we created the O&M's (operations & maintenance manual) this includes test certificates, and data sheets.",
  },
];

export const longcrossStudiosProjectHeader = {
  id: "longcross-studios-project-header",
  titleTop: "Featured Project",
  titleBottom: "Longcross Studios",
  link: "/projects/longcross-studios",
  content:
    "Longcross Studios, a large commercial project undertaken in the -------- umbrella, providing professional electrical installations lighting and power services. images required",
};

export const longcrossStudiosProjectDetails = [
  {
    id: "longcross-studios-1",
    title: "Meeting Expectations",
    content:
      "Start of the DHL project, Da Vinci's alluring portrait has been dogged by two questions since the day it was made: Who's the subject and why is she smiling?.",
  },
  {
    id: "longcross-studios-2",
    title: "Skilled craftsmanship",
    content:
      "Professional wiring services, DHL project popular painting, The Starry Night was created by Van Gogh at the asylum in Saint-Rémy, where he'd committed himself",
  },
  {
    id: "longcross-studios-3",
    title: "Power Unit",
    content:
      "Professionalism from our experienced work force patterned, T DHL project he Kiss, Gustav Klimt's fin-de-siècle portrayal of intimacy, is a mix of Symbolism.",
  },
  {
    id: "longcross-studios-4",
    title: "Power Outlets",
    content:
      "Where expectation becomes a reality for the common man is considered one of the defining works of Western art. This composition was one of six created..",
  },
];

// Testimonial dark - still to do

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Great job changing my fusebox. Quick, friendly, well priced. Would definitely use again for any electrical work.",
    name: "Leanda",
    type: "Domestic customer",
    title: "May, 2022",
    link: "https://www.mybuilder.com/job/view/8111279",
    alt: "my builder.com review by Leanda domestic customer",
  },
  {
    id: "feedback-2",
    content:
      "Replacement of two electrical outdoor lights. Really professional, going to recommend to friends who need work doing",
    name: "Maryann",
    type: "Domestic customer",
    title: "Mar, 2021",
    link: "https://www.mybuilder.com/job/view/7996153",
    alt: "my builder.com review by Maryann domestic customer",
  },
  {
    id: "feedback-3",
    content:
      "On time, polite, professional, and the job was done as required, at the price agreed. Would recommend and hire again.",
    name: "Deborah",
    type: "Commercial customer",
    title: "Feb, 2021",
    link: "https://www.mybuilder.com/job/view/7881221",
    alt: "my builder.com review by Deborah - commercial customer",
  },
  {
    id: "feedback-4",
    content:
      "Wood cabin needed electrics fitted. The work was carried out in one day and to a high standard. Thank you.",
    name: "Mike",
    type: "Domestic customer",
    title: "Mar, 2021",
    link: "https://www.mybuilder.com/job/view/7881221",
    alt: "my builder.com review by Mike domestic customer",
  },
];

// Stats unused data

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

/* export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
]; */

export const domesticQuestions = [
  {
    question: "How often should the electrics in my home be checked? ",
    answer:
      "The Electrical installation within your home should be inspected and tested by having an EICR (electrical installation condition report) carried out every 10 years for a owner occupied home and every 5 years for a rented home.",
  },
  {
    question: "What are the signs that a circuit breaker needs replacing?",
    answer:
      "The circuit breaker trips frequently. There is a burning smell coming from the fuse board. The circuit breaker fails to stay in the on position. There are physical signs of damage to the circuit breaker. Light fittings or appliances that are connected to the circuit breaker start to drop in performance.",
  },
  {
    question: "Can the electrics in my home cause a fire?",
    answer:
      "Yes, fires are mainly caused by overloading a circuit but a perfectly working circuit breaker should protect against that, also poor or loose connections within a circuit can cause arcing and heat build up which could cause a fire.",
  },
  {
    question: "How do I find a good electrician?",
    answer:
      "You already have, our contact details are on this website. Intact Electrical Ltd are reliable, professional, registered and approved electrical contractors and installers, with decades of invaluable knowledge. We provide every client with quality services and an exceptional customer experience.",
  },
];

export const commercialQuestions = [
  {
    question: "How often should commercial premises have their electrics tested?",
    answer:
      "Commercial premises should be inspected and tested by having an EICR (electrical installation condition report) carried out a minimumm of every 5 years.",
  },
  {
    question: "Why should I have the electrics within my commercial premises checked?",
    answer:
      "Faulty or old electrical systems can cause fires and damage to your property and appliances, so it’s definitely a good idea to have them assesses regularly.",
  },
  {
    question: "How can I maintain my commercial or industrial electrical installation?",
    answer:
      "You can maintain safe and compliant working electrics by having then regularly inspected and tested, ensuring you have an up to date EICR (Electrical Installation Condition Report).",
  },
  {
    question: "Who could maintain my commercial or industrial electrical installation to ensure that it’s safe and complies with current regulations? ",
    answer:
      "Intact Electrical can offer you a complete maintenance package for any type or size of electrical installation that you require, keeping you, your property and employees electrically safe and compliant with current regulations.",
  },
];
