import React from "react";
import styles from "../styles/style";
//import { logo } from "../images";
import {
  footerLinks,
  //socialMedia
} from "../constants";
//import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const currentYear = new Date().getFullYear();

const Footer = () => (
  <section
    className={`${styles.flexCenter} ${styles.paddingY} flex-col mt-[20px] mb-[20px]`}
  >
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-10 mb-8">
        <div className="" style={{ maxWidth: "720px" }}>
          <StaticImage
            src="../images/logos/intact-electrical-border-logo-banner.png"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt="Intact electrical logo banner"
            //aspectRatio={1.99}
            loading="lazy"
          />
        </div>

        {/* <p
          className={`${styles.paragraph3} text-white  lg:max-w-[720px]  ss:max-w-[100%] mt-3 `}
        >
          At Intact Electrical we provide a complete service, whether you are
          looking to install garden lighting - security lighting - a new
          lighting circuit or lighting for outbuildings then look no further
          than us.
        </p> */}
      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0">
        {footerLinks.map((footerlink) => (
          <div
            key={footerlink.title}
            className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}
          >
            <h4 className="font-bold text-[18px] leading-[27px]">
              <span className="text-gradient-yellow">{footerlink.title}</span>
            </h4>
            <ul className="list-none mt-4">
              {footerlink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`${
                    styles.paragraph3
                  } hover:text-quinary cursor-pointer text-white ${
                    index !== footerlink.links.length - 1 ? "mb-4" : "mb-0"
                  }`}
                >
                  <Link to={link.link} alt={link.name} activeClassName="active">{link.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 pb-6 border-t-[2px] border-t-quinary">
      <p className={`${styles.paragraph3} text-white text-center`}>
        Copyright Ⓒ {currentYear}{" "}
        <span className="text-gradient-yellow font-bold">
          Intact Electrical Ltd
        </span>{" "}
        - All Rights Reserved.
      </p>

      <p className={`${styles.paragraph3} text-white text-center`}>
        Designed & built by{" "}
        <span className="text-gradient-yellow font-bold">
          <a
            href="https://www.adudev.co.uk/"
            alt="Adu Dev website"
            title="Adu Dev website"
            target="_blank"
            rel="noreferrer"
          >
            Adu Dev Ltd
          </a>
        </span>{" "}
        powered by Gatsby.
      </p>

      {/* <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] h-[21px] object-contain cursor-pointer icon  grow  ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
            role="presentation"
          />
        ))}
      </div> */}
    </div>
  </section>
);

export default Footer;
